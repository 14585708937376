@use 'sass:map';
@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.optionsModal__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ellipsis {
  transform: rotate(90deg);

  @include mixins.medium {
    transform: rotate(0);
  }
}

.optionsModal__reveal {
  position: absolute;
  top: 40px;
  left: calc(-220px + 100%);
  height: max-content;
  min-width: 22rem;
  border: solid 2px colors.$color-gray-2;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 30px;
  background: colors.$color-white;
  padding: 2rem 2rem;

  z-index: map.get(variables.$z-index, 'modal-optionsmenu');

  @include mixins.large {
    left: 0;
    font-size: 1.8rem;
  }
}

.profileExternalViewModal .optionsModal__reveal {
  @include mixins.large {
    left: calc(-220px + 100%);
  }
}

.optionsModal__section {
  text-align: left;

  &:not(:last-child) {
    border-bottom: solid 2px colors.$color-gray-2;
    padding-bottom: 2rem;
  }

  &:first-child {
    .optionsModal__sectionTitle {
      padding-top: 0;
      padding-bottom: 2rem;
    }
  }
}

.optionsModal__section + .optionsModal__section,
.optionsModal__form + .optionsModal__section {
  padding-top: 2rem;
}

.optionsModal__section + .submitButton {
  margin-top: 2rem;
}

.optionsModal__sectionTitle {
  padding-top: 2rem;

  text-align: center;
}

.optionsModal__text {
  font: 400 1.8rem/1.5 variables.$font-stack-default;
}

.optionsModal__alwaysPrivate {
  text-align: center;
  font: 700 italic 1.4rem/1.5 variables.$font-stack-default;
}

.optionsModal__save {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.submitButton + .deleteButton {
  margin-top: 1.6rem;
}

.optionsModal__delete {
  display: block;
  margin: 0 auto;
  padding: 1rem 3rem;

  font-size: 1.8rem;
  font-weight: 500;

  &--danger {
    border-color: colors.$color-alert;

    color: colors.$color-alert;
  }
}

.optionsModal__contactUs {
  display: block;
  margin: 0 auto;

  font: 500 1.8rem/1.5 variables.$font-stack-default;

  &:hover {
    cursor: pointer;
  }
}

.selectPrimary__isPrimaryText {
  margin: 0;

  text-align: left;
  font: 400 1.8rem/1.5 variables.$font-stack-default;
}

.partyMemberSettings__popupTitle {
  text-align: center;
}
