@use '../../utils/colors';
@use '../../utils/mixins';

$table-row-gap: 2.4rem;

.reviewRegistration__instructions {
  margin: 0;

  text-align: center;
}

.reviewRegistration__instructions + .reviewRegistration__form {
  margin-top: 8rem;
}

.reviewRegistration__event {
  position: relative;

  & + & {
    margin-top: 6.4rem;
  }
}

.reviewRegistration__eventTitleWrapper {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.reviewRegistration__eventTitleWrapper + .reviewRegistration__form {
  margin-top: 1.6rem;
}

.reviewRegistration__eventTitle {
  margin: 0;
}

.reviewRegistration__listHeader {
  margin: 0;
  border-bottom: solid 1px colors.$color-gray-5;
  padding-bottom: 0.4rem;
}

.reviewRegistration__listHeader + .reviewRegistration__tableWrapper {
  margin-top: 1.6rem;
}

.reviewRegistration__table {
  table-layout: auto; // this allows the remove button cell to shrink to its content size
  margin: -#{calc($table-row-gap * 2)} 0 -#{$table-row-gap} 0; // negative margins hide the top and bottom border spacing on the table
  border: none;
  border-collapse: separate;
  border-spacing: 0 $table-row-gap;
}

.reviewRegistration__nameCell,
.reviewRegistration__optionCell {
  display: block; // Stack these on mobile
  border: none;

  @include mixins.medium {
    display: table-cell;
  }
}

.reviewRegistration__nameCell {
  @include mixins.medium {
    width: 40%;
  }
}

.reviewRegistration__optionCell {
  @include mixins.medium {
    width: 60%;
  }
}

.reviewRegistration__removeButtonCell {
  border: none;
  // somehow these 3 properties make the cell shrink to fit the content ¯\_(ツ)_/¯
  width: 1%;
  white-space: nowrap;
  // overflow: hidden;
  // end shrink to fit content properties
}

.reviewRegistration__form + .reviewRegistration__addGuestWrapper {
  margin-top: 1.6rem;
}

.reviewRegistration__addGuestWrapper {
  @include mixins.medium {
    display: flex;
    justify-content: center;

    text-align: right;
  }
}

.reviewRegistration__addGuestButton {
  margin-left: auto;
}

.reviewRegistration__additionalInfoFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .additionalInfoForm__fieldset {
    width: 100%;

    @include mixins.medium {
      width: 40rem;
    }
  }
}

.eventRegDetailsCard + .reviewRegistration__reCaptcha {
  margin-top: 6.4rem;
}

.reviewRegistration__reCaptcha {
  .googleReCaptcha__box {
    width: 304px; // Match the fixed reCAPTCHA box size
    margin-left: auto;
    margin-right: auto;
  }

  .googleReCaptcha__error {
    text-align: center;
  }
}

.reviewRegistration__generalError {
  margin: 4rem auto 0 auto;
  text-align: center;
}
