@use '../../utils/colors';

.eventRegistrantName {
  display: flex;
  align-items: baseline;
  gap: 0.8rem;
  margin: 0;
}

.eventRegistrantName__icon {
  &--currentHost {
    color: colors.$color-new-gold;
  }

  &--formerHost,
  &--guest {
    color: colors.$color-black;
  }

  &--waitlist {
    color: colors.$color-gray-3;
  }
}

.eventRegistrantName + .eventRegistrantName__roleLabel {
  margin-top: 0.4rem;
}

.eventRegistrantName__roleLabel {
  margin: 0;
}
